/* 

SECTION GENERICS

*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

input {
  background-color: none;
  border: none;
}

body {
  overflow-y: scroll;
  scrollbar-width: 0;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

body::-webkit-scrollbar {
  display: none; /* For Webkit browsers like Chrome and Safari */
}

/*************************************

LANDING SECTION

*/

#landing {
  height: 100vh;
  padding: 50px;
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #001033, #001b3f, #00254c);
}

.landing__row {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

#landing,
.landing__name,
.landing__contact,
.landing__role,
.landing__social,
.landing__social--links,
.menu,
.menu-icon,
.menu__list {
  z-index: 10;
}

.landing__top-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 60%;
  margin-bottom: 16px;
}

.landing__name {
  color: #fff;
  font-size: 120px;
  font-family: "Roboto", system-ui;
  font-weight: 300;
}

.landing__contact {
  position: relative;
  width: 150px;
  margin-top: 24px;
  border: none;
  background: #f1b900;
  color: #15365f;
  cursor: pointer;
  padding: 1em;
  font-weight: 800;
  text-align: center;
  font-family: "Roboto", system-ui;
  text-transform: uppercase;
  border-radius: 5px;
  letter-spacing: 1px;
  box-shadow: #906700 0px 7px 2px, #ffffff 0px 8px 5px;
}

.landing__contact:hover {
  background: #f1b900e7;
}

.landing__contact:active {
  top: 4px;
  box-shadow: #906700 0px 3px 2px,#edf0fc9b 0px 3px 5px;
}

#form__submit:hover {
  box-shadow: 1px 9px 0 0 #906700;
}

#form__submit:active {
  transform: translateY(4px);
  box-shadow: 0px 0px 0 0 #906700;
}

.landing__bottom-content {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.landing__role {
  font-size: 90px;
  font-family: "Roboto", system-ui;
  font-weight: 200;
  color: #edf0fc;
}

.landing__social {
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border: 1px solid gray;
  padding: 20px 25px;
  border-radius: 14px;
}

.landing__social--links {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  position: relative;
  border-radius: 7px;
  cursor: pointer;
  transition: all 0.25s;
}

.social-icons--container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  backdrop-filter: blur(0px);
  letter-spacing: 0.8px;
  border-radius: 10px;
  transition: all 0.25s;
  border: 1px solid rgba(156, 156, 156, 0.466);
}

.social--background {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #181818;
  z-index: -1;
  border-radius: 10px;
  pointer-events: none;
  transition: all 0.25s;
}

.github .social--background {
  background: #ffffffdb;
}
.linkedin .social--background {
  background: #0077b5;
}
.resume .social--background {
  background: linear-gradient(to left, #fe2712, #ea2113, #d51a13, #c11414);
}

.landing__social--links:hover .social--background {
  transform: rotate(35deg);
  transform-origin: bottom;
}

.landing__social--links:hover .social-icons--container {
  background-color: rgba(156, 156, 156, 0.466);
  backdrop-filter: blur(4px);
}

.water-ripple {
  position: absolute;
  border-radius: 50%;
  background-image: linear-gradient(
    to bottom,
    #001033,
    #001b3f,
    #00254c,
    #002f59,
    #003865
  );
  transform: scale(0);
  filter: blur(40px);
  animation: ripple-animation 1s ease-out;
  z-index: 1;
}

@keyframes ripple-animation {
  0% {
    transform: scale(0);
    opacity: 0.6;
  }
  5% {
    transform: scale(0.029);
    opacity: 0.551;
  }
  10% {
    transform: scale(0.058);
    opacity: 0.522;
  }
  15% {
    transform: scale(0.087);
    opacity: 0.493;
  }
  20% {
    transform: scale(0.116);
    opacity: 0.464;
  }
  25% {
    transform: scale(0.145);
    opacity: 0.435;
  }
  30% {
    transform: scale(0.174);
    opacity: 0.406;
  }
  35% {
    transform: scale(0.203);
    opacity: 0.377;
  }
  40% {
    transform: scale(0.232);
    opacity: 0.348;
  }
  45% {
    transform: scale(0.261);
    opacity: 0.319;
  }
  50% {
    transform: scale(0.29);
    opacity: 0.29;
  }
  55% {
    transform: scale(0.319);
    opacity: 0.261;
  }
  60% {
    transform: scale(0.348);
    opacity: 0.232;
  }
  65% {
    transform: scale(0.377);
    opacity: 0.203;
  }
  70% {
    transform: scale(0.406);
    opacity: 0.174;
  }
  75% {
    transform: scale(0.435);
    opacity: 0.145;
  }
  80% {
    transform: scale(0.464);
    opacity: 0.116;
  }
  85% {
    transform: scale(0.493);
    opacity: 0.087;
  }
  90% {
    transform: scale(0.522);
    opacity: 0.058;
  }
  95% {
    transform: scale(0.551);
    opacity: 0.029;
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
}

/* Menu Section */

.menu {
  position: absolute;
  font-family: "Roboto", system-ui;
  font-weight: 300;
  letter-spacing: 2px;
  display: flex;
  left: -50%;
  margin-top: -3px;
  margin-left: 70px;
  transition: all 1s ease-in-out;
}

.menu.open {
  left: 0;
}

.menu__content {
  position: relative;
  font-size: 32px;
  font-family: "Lora", system-ui;
  color: #fff;
  list-style-type: none;
  margin: 0 20px;
}

/* how to make zoomy line left to right hover on anchor tags, make sure to add position relative to the text you want zoomy line */
.link__hover-effect:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: white;
  width: 0;
  bottom: -3px;
  right: 0;
  transition: all 300ms ease;
}

.link__hover-effect:hover:after {
  width: 100%;
  left: 0;
}

.menu-icon {
  position: absolute;
  left: 0;
  cursor: pointer;
  /* transform: rotateX(50deg) rotateZ(30deg); */
  transition: all 0.25s;
}

.menu-icon:hover {
  transform: rotateX(30deg) rotateZ(0); /*aos messes up my hover things */
}

.menu-icon input {
  display: none;
}

.menu-icon svg {
  height: 3em;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-top-bottom {
  stroke-dasharray: 12 63;
}

.menu-icon input:checked + svg {
  transform: rotate(-45deg);
}

.menu-icon input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

/*************************************

STACK SECTION

*/

#stack {
  padding: 50px 0;
  height: 80vh;
  background-image: linear-gradient(to bottom, #00254c, #002f59, #003865);
}

.stack__container {
  height: 100%;
  width: 100%;
  position: relative;
}

.stack__row {
  height: 100%;
  display: flex;
  border-top: 1px solid #edf0fc;
  border-bottom: 1px solid #edf0fc;
  background-color: #919191d4;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stack__title {
  color: #fff;
  font-size: 56px;
  font-family: "Roboto", system-ui;
  font-weight: 400;
  margin-bottom: 40px;
}

.stack__contents--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
}

.stack__content--wrapper {
  height: 180px;
  padding: 10px;
}

.stack__img {
  height: 120px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

.language__name {
  text-align: center;
  transform: scale(0);
  font-size: 26px;
  letter-spacing: 1px;
}

.html {
  color: #ffa05c;
}

.css {
  color: #56d2ff;
}

.javascript {
  color: #f5de19;
}

.react {
  color: #73dffd;
}

.white {
  color: white;
}

.typescript--alt {
  color: #7bbdff;
}

.redux {
  color: #764abc;
}

.firebase {
  color: #ffcb2b;
}

.firebase--med {
  color: #ffa611;
}

.stack__img,
.language__name {
  transition: all 0.25s ease;
}

.stack__content--wrapper:hover .language__name {
  transform: scale(1);
}

.stack__content--wrapper:hover .stack__img {
  transform: scale(0.9);
  filter: brightness(90%);
}

/* Sticky pen */

.floating-contact {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 40px;
  z-index: 2;
}

.floating-contact--editBtn {
  width: 55px;
  height: 55px;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.123);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.25s ease-in-out;
  background-image: linear-gradient(
    to top,
    #c3a456,
    #b19341,
    #a0822c,
    #8f7217,
    #7e6102
  );
}

.floating-contact--editBtn::before {
  content: "";
  width: 200%;
  height: 200%;
  position: absolute;
  z-index: 1;
  transform: scale(0);
  transition: all 0.25s ease-in-out;
  border-radius: 50%;
  filter: blur(10px);
  background-image: linear-gradient(
    to bottom,
    #c3a456,
    #b19341,
    #a0822c,
    #8f7217,
    #7e6102
  );
}

.floating-contact--editBtn:hover::before {
  transform: scale(1);
}

.floating-contact--editBtn:hover {
  box-shadow: 0px 5px 10px #c3a456;
}

.floating-contact--editBtn svg {
  height: 17px;
  fill: white;
  z-index: 3;
  transition: all 0.2s;
  transform-origin: bottom;
}
.floating-contact--editBtn:hover svg {
  transform: rotate(-15deg) translateX(5px);
}
.floating-contact--editBtn::after {
  content: "";
  width: 25px;
  height: 1px;
  position: absolute;
  bottom: 19px;
  left: -5px;
  background-color: white;
  border-radius: 2px;
  z-index: 2;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s ease-out;
}
.floating-contact--editBtn:hover::after {
  transform: scaleX(1);
  left: 0px;
  transform-origin: right;
}

/*************************************
    
PROJECTS SECTION

*/

li {
  list-style: none;
}

#projects {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(
    to bottom,
    #003865,
    #004271,
    #004d7e,
    #00578a
  );
}

.projects__title {
  font-size: 56px;
  text-align: center;
  font-family: "Roboto", system-ui;
  font-weight: 400;
  color: #fff;
}

.projects__list {
  max-width: 1000px;
  width: 100%;
  padding: 24px;
  height: 100%;
}

.project {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  box-shadow: 0px 4px 8px #f0f0f7ca;
  background-color: rgba(145, 145, 145, 0.83);
  border-radius: 10px;
  padding: 20px 60px;
  margin-bottom: 40px;
}

.project:last-child {
  margin-bottom: 0;
}

.project__details {
  height: 30%;
}

.project__title {
  text-align: center;
  padding-left: 10px;
  font-size: 30px;
  font-family: "Roboto", system-ui;
  font-weight: 600;
}

.project__desc,
.project__tech {
  font-weight: 300;
  margin-bottom: 16px;
}

.project__desc {
  text-align: center;
  padding-top: 16px;
}

.project__tech {
  padding-left: 28px;
}

.project__visuals {
  height: 70%;
}

.project__carousel {
  max-width: 800px;
  padding-left: 32px;
}

.project__images {
  width: 100%;
  height: 100%;
}

.project__links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project__link {
  transition: all 0.25s ease;
  padding: 0 5px;
  margin-top: 10px;
}

.project__link:hover {
  scale: 1.2;
}

/************************************* 

CONTACT SECTION

*/

#contact-section {
  width: 100%;
  height: 48px;
  background-color: #00578a;
  border-bottom: 1px solid #edf0fc;
}

#contact {
  height: 90vh;
  padding: 50px;
  background-color: rgb(113, 113, 113);
}

.contact__desc,
.contact__title {
  color: #f0f0f7;
}

.contact__title {
  font-size: 100px;
  font-family: "Lora", system-ui;
  font-weight: 400;
}

.contact__desc {
  font-size: 30px;
  font-family: "Roboto", system-ui;
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: 20px;
}

#contact__form {
  max-width: 1000px; 
  margin: 0 auto;
  padding: 70px 30px 50px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 6px;
  box-shadow: 0px 4px 8px #edf0fca8;
  background-image: linear-gradient(
    to bottom right,
    #001033,
    #001b3f,
    #00254c,
    #002f59,
    #003865,
    #004271,
    #004d7e,
    #00578a
  );
}

.input:focus-visible {
  outline: none;
}

.input {
  border: none;
  border-bottom: solid white 1px;
  background: transparent;
  outline: none;
  padding: 10px;
  font-family: "Roboto", system-ui;
  color: white;
  font-size: 18px;
}

.input:focus,
.input:valid {
  border-color: lightblue;
}

label span {
  position: absolute;
  top: 10px;
  display: block;
  transition: 0.2s;
  pointer-events: none;
  font-size: 25px;
  color: gray;
  opacity: 0%;
}

.input:focus + label span,
.input:valid + label span {
  color: rgb(206, 243, 255);
  top: -42px;
  opacity: 100%;
}

.input:focus + label p,
.input:valid + label p {
  opacity: 0;
}

.form__item {
  position: relative;
  margin: 60px auto 30px auto;
  padding: 0 10px;
}

.form__item input {
  width: 35%;
}

.form__item textarea {
  width: 75%;
}

.form__text--transition {
  transition-delay: 0ms;
  left: 10px;
}

.form__text--transition-1 {
  transition-delay: 75ms;
  left: 22px;
}

.form__text--transition-2 {
  transition-delay: 150ms;
  left: 43px;
}

.form__text--transition-3 {
  transition-delay: 225ms;
  left: 55px;
}

.form__text--transition-4 {
  transition-delay: 300ms;
  left: 61px;
}
.form__text--transition-5 {
  transition-delay: 375ms;
  left: 72px;
}
.form__text--transition-6 {
  transition-delay: 450ms;
  left: 85px;
}

.title__t--left {
  left: 28px;
}

.title__l--left {
  left: 37px;
}

.title__e--left {
  left: 43px;
}

.message__m--left {
  left: 125px;
}

.message__e--left {
  left: 147px;
}

.message__s--left {
  left: 160px;
}

.message__s--left-1 {
  left: 171px;
}

.message__a--left {
  left: 182px;
}

.message__g--left {
  left: 194px;
}

.message__e--left-1 {
  left: 209px;
}

.form__item--text {
  position: absolute;
  left: 0px;
  top: -10px;
  font-size: 24px;
  margin: 10px;
  color: #edf0fc;
  transition: 0.5s;
  pointer-events: none;
}

.textarea {
  width: 75%;
  max-height: 180px;
  resize: vertical;
}

.textarea-display {
  left: 115px;
  top: 0px;
}

#form__submit {
  min-width: 200px;
  min-height: 60px;
  position: relative;
  background-color: #f1b900;
  border-radius: 1em;
  cursor: pointer;
  transition: 0.1s ease;
  border-width: 0;
  box-shadow: 1px 5px 0 0 #906700;
}

.form__submit--input,
.form__submit--sent {
  position: relative;
  background-color: transparent;
  color: #15365f;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 20px;
  transition: 0.1s ease;
}

/* Loading and Sent styles */

.form__submit--loading {
  padding: 15px 20px;
}

.loader-spanne-20 {
  position: relative;
  margin: 0 auto;
  width: 100px;
  height: 30px;
  padding: 0;
}
.loader-spanne-20 > span {
  position: absolute;
  right: 0;
  width: 3px;
  height: 30px;
  background-color: #edf0fc;
  display: block;
  border-radius: 3px;
  transform-origin: 50% 100%;
  animation: move 2.8s linear infinite;
}
.loader-spanne-20 > span:nth-child(1) {
  animation-delay: -0.4s;
}
.loader-spanne-20 > span:nth-child(2) {
  animation-delay: -0.8s;
}
.loader-spanne-20 > span:nth-child(3) {
  animation-delay: -1.2s;
}
.loader-spanne-20 > span:nth-child(4) {
  animation-delay: -1.6s;
}
.loader-spanne-20 > span:nth-child(5) {
  animation-delay: -2s;
}
.loader-spanne-20 > span:nth-child(6) {
  animation-delay: -2.4s;
}
.loader-spanne-20 > span:nth-child(7) {
  animation-delay: -2.8s;
}
@keyframes move {
  0% {
    opacity: 0;
    transform: translateX(0px) rotate(0deg);
  }
  20% {
    opacity: 1;
  }
  40% {
    transform: translateX(-40px) rotate(0deg);
  }
  50% {
    opacity: 1;
    transform: translateX(-50px) rotate(22deg);
  }
  85% {
    opacity: 1;
    transform: translateX(-85px) rotate(60deg);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px) rotate(65deg);
  }
}

/*************************************

FOOTER SECTION

*/

#footer {
  height: 35vh;
  padding: 40px 0;
  background-color: black;
  border-top: 1px solid #edf0fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer__row {
  margin: 0 auto;
  min-width: 100px;
}

.footer-btn {
  width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.footer-btn--display,
.footer-btn--hover {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  transition: all 0.25s ease;
}

.footer-btn--hover {
  opacity: 0;
}

.my-logo {
  width: 60px;
  box-shadow: 0px 3px 6px rgb(226, 226, 226);
}

.my-logo--image {
  width: 100%;
}

.footer-btn--hover {
  position: absolute;
  inset: 0;
  transform: translate(0%, 100%);
  box-shadow: 0px 3px 6px rgb(226, 226, 226);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.footer-btn--text {
  text-align: center;
  font-family: "Roboto", system-ui;
  font-weight: 400;
}

.footer-btn:hover .footer-btn--display {
  transform: translate(0%, -100%);
  opacity: 0;
}

.footer-btn:hover .footer-btn--hover {
  transform: translate(0%, 0%);
  opacity: 1;
}

.footer__copyright {
  text-align: center;
  font-size: 16px;
  padding-top: 24px;
  width: 300px;
  z-index: 999;
}

.footer__social {
  display: none;
  height: fit-content;
  width: fit-content;
  gap: 20px;
  padding: 20px 25px;
  margin-top: 20px;
  border-radius: 14px;
}

/* 

RESPONSIVENESS

*/

@media (max-width: 1200px) {
  .landing__name,
  .contact__title {
    font-size: 90px;
  }

  .landing__role {
    font-size: 70px;
  }

  .contact__desc {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .textarea {
    max-height: 180px;
  }

  .menu {
    left: -150%;
    margin-left: 50px;
    margin-top: -2px;
  }

  .menu-icon {
    transform: translate(0);
  }
}

@media (max-width: 1030px) {
  .project__carousel {
    padding-left: 0;
  }

  .project__tech {
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .landing__name,
  .contact__title {
    font-size: 60px;
  }

  .landing__role {
    font-size: 50px;
  }

  .landing__social {
    display: none;
  }

  .stack__content--wrapper {
    padding: 0;
  }

  .stack__img {
    height: 106px;
  }

  .language__name {
    transform: scale(1);
  }

  .textarea-display {
    left: 70px;
  }

  .footer__social {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer__copyright {
    padding-top: 0;
  }
}

@media (max-width: 480px) {
  #landing {
    padding: 25px;
  }

  .landing__name {
    font-size: 55px;
  }

  .landing__name--mobile-display {
    display: none;
  }

  .landing__role {
    font-size: 40px;
  }

  .landing__contact {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.6px;
    padding: 10px 15px;
  }

  .menu__content {
    font-size: 28px;
    margin: 0 15px;
  }

  .menu {
    margin-left: 45px;
    margin-top: 2px;
  }

  .stack__img {
    height: 80px;
  }

  .language__name {
    font-size: 20px;
  }

  .stack__content--wrapper {
    height: 140px;
  }

  #projects {
    padding: 0;
  }

  .project {
    padding: 20px 33px;
  }

  .project__desc,
  .project__tech {
    display: none;
  }

  .project__visuals {
    margin-top: 20px;
  }

  #contact {
    padding: 20px;
  }

  .contact__title {
    font-size: 48px;
  }

  .contact__desc {
    font-size: 20px;
    letter-spacing: 1px;
  }

  #contact__form {
    height: 100%;
    max-height: 500px;
  }

  .textarea {
    min-height: 200px;
    height: 100%;
  }

  #form__submit {
    min-width: 130px;
    min-height: 50px;
  }

  .textarea-display {
    left: 47px;
  }

  .form__submit--input,
  .form__submit--sent {
    font-size: 20px;
  }

  .message__m--left {
    left: 60px;
  }

  .message__e--left {
    left: 82px;
  }

  .message__s--left {
    left: 94px;
  }

  .message__s--left-1 {
    left: 105px;
  }

  .message__a--left {
    left: 116px;
  }

  .message__g--left {
    left: 128px;
  }

  .message__e--left-1 {
    left: 142px;
  }
}

@media (max-width: 430px) {
  .menu {
    margin-left: 38px;
    margin-top: -1px;
  }

  .menu__content {
    font-size: 25px;
    margin: 0 8px;
  }

  .menu-icon svg {
    height: 38px;
  }

  .landing__name {
    font-size: 42px;
  }

  .landing__role {
    font-size: 32px;
  }

  .contact__title {
    font-size: 35px;
    margin-bottom: 60px;
  }

  .contact__desc {
    display: none;
  }

  #contact__form {
    height: 100%;
    max-height: 600px;
  }

  .textarea {
    min-height: 300px;
    height: 100%;
  }

  .textarea-display {
    left: 40px;
  }

  .form__submit--input,
  .form__submit--sent {
    font-size: 20px;
  }

  .message__m--left {
    left: 50px;
  }

  .message__e--left {
    left: 73px;
  }

  .message__s--left {
    left: 86px;
  }

  .message__s--left-1 {
    left: 96px;
  }

  .message__a--left {
    left: 107px;
  }

  .message__g--left {
    left: 119px;
  }

  .message__e--left-1 {
    left: 133px;
  }
}
